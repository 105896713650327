import React from 'react'
import './style.css'

import homeA from '../../Images/Bottom/home-a.png'
import home from '../../Images/Bottom/home.png'

import invA from '../../Images/Bottom/invest-a.png'
import inv from '../../Images/Bottom/invest.png'

import team from '../../Images/Bottom/team.png'
import teamA from '../../Images/Bottom/team-a.png'

import mine from '../../Images/Bottom/mine.png'
import mineA from '../../Images/Bottom/mine-a.png'

import reward from '../../Images/Bottom/reward.png'
import rewardA from '../../Images/Bottom/reward-a.png'

const index = ({ page }) => {
  const item = [
    {
      item: 'Home',
      path: '/#/',
      img: home,
      active: homeA
    },
    {
      item: 'Invest',
      path: '/#/investments',
      img: inv,
      active: invA
    },
    {
      item: 'Team',
      path: '/#/my/team',
      img: team,
      active: teamA
    },
    {
      item: 'Reward',
      path: '/#/reward',
      img: reward,
      active: rewardA
    },
    {
      item: 'Me',
      path: '/#/mine',
      img: mine,
      active: mineA
    }
  ]

  return (
    <div className='bottom--nav'>
      {item.map((item, index) => {
        return (
          <div className="col--container" key={index} onClick={() => {
            if (item.item === page) {
              return;
            } else {
              window.location.href = item.path
            }
          }}>
            <div className="col--icon">
              <img src={page === item.item ? item.active : item.img } alt="" />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default index