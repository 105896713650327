import React, { useState, useEffect } from 'react'
import '../CSS/Reward.css'
import axios from 'axios'
import copy from 'copy-to-clipboard';

import BottomNav from '../Components/BottomBar/index'

import Error from '../Components/Error'
import Loading from '../Components/Loading'

const Reward = ({ token }) => {
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [id, setId] = useState('xxxxxxxx')
    const [name, setName] = useState('XXXXX XXX')
    const [size, setSize] = useState(0)
    const [level, setLevel] = useState([])

    function copyLink() {
        copy(window.location.origin + '/#/register?invite_code=' + id)
        setIsError(true)
        setErrorText('Link Copied')
        const timerId = setTimeout(() => {
            setIsError(false)
            setErrorText('')
        }, 3000);

        return () => clearTimeout(timerId);
    }

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/index`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setId(data.id)
                setName(data.name)
                setLevel(data.referrals.levels);
                setSize(data.referrals.verified)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])


    const rewards = [10, 20, 50, 100, 200, 300, 400, 500]


    async function onClaimReward(prop) {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/claim/reward?level=${prop}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setLevel(prevLevel => [...prevLevel, prop]);
                setSize(data.referrals)

                setLoader(false)

                setIsError(true)
                setErrorText('Claimed Successfully')

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    return (
        <div className='pg rewards'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="header">Referral Rewards</div>

            <div className="invite--bx--container">
                <div className="invite--bx">
                    <div className="bx--top">
                        <div className="bx--tpl">
                            <div className="inviter--name">{name}</div>
                            <div className="inviter--link">{window.location.origin}/#/register?invite_code={id}</div>
                        </div>
                        <div className="li--copy" onClick={copyLink}>Copy</div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="tit">My Verified Referrals: <span>{size}</span></div>
                {rewards.map((item, index) => {
                    let isLevelClaimed = level.includes(index + 1);
                    let isAvailableToClaim = size >= item;

                    return (
                        <div className="box" key={index}>
                            <div className="box--top">
                                <div className="box--top--left">
                                    <div className="pro--img">{index + 1}</div>
                                    <div className="box--top--left--right">
                                        <div className="pro--name">Reward amount</div>
                                        <div className="pro--cost">₹{item * 80}</div>
                                    </div>
                                </div>

                                {isLevelClaimed ? <button className="purchase--btn no">Claimed</button> : isAvailableToClaim ? <button className="purchase--btn" onClick={() => onClaimReward(index + 1)}>Claim</button> : <button className="purchase--btn no">{size} / {item}</button>}
                            </div>
                            <div className="box--bottom">
                                <div className="txt">Invite {item} users to register and recharge upto ₹400 to claim this reward.</div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <BottomNav page="Reward" />
        </div>
    )
}

export default Reward