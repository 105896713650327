import React, { useState, useEffect } from 'react'
import '../CSS/Me.css'

import BottomNav from '../Components/BottomBar/index'
import axios from 'axios'
import Error from '../Components/Error'
import Loading from '../Components/Loading'

import logo from '../Images/logo.png'

const Me = ({ token }) => {
    const [balance, setBalance] = useState(0)
    const [income, setIncome] = useState(0)
    const [commission, setCommission] = useState(0);

    const [name, setName] = useState('XXXXX XXX')
    const [phone, setPhone] = useState('+91 XXXXXXXXXX')

    const [loader, setLoader] = useState(false)
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')

    const options = [
        {
            item: 'Add Bank Account',
            action: () => {
                window.location.href = '/#/account'
            },
            img: 'https://cdn-icons-png.flaticon.com/128/6295/6295635.png'
        },
        {
            item: 'Fund Records',
            action: () => {
                window.location.href = '/#/funds/records'
            },
            img: 'https://cdn-icons-png.flaticon.com/128/12740/12740855.png'
        },
        {
            item: 'Recharge Records',
            action: () => {
                window.location.href = '/#/recharge/records'
            },
            img: 'https://cdn-icons-png.flaticon.com/128/12456/12456962.png'
        },
        {
            item: 'Withdrawal Records',
            action: () => {
                window.location.href = '/#/withdraw/records'
            },
            img: 'https://cdn-icons-png.flaticon.com/128/6184/6184467.png'
        },
        {
            item: 'How to make money?',
            action: () => {
                window.location.href = '/#/how-to-make-money'
            },
            img: 'https://cdn-icons-png.flaticon.com/128/10237/10237999.png'
        },
        {
            item: 'Customer Support',
            action: () => {
                window.location.replace('tg://resolve?domain=oxyapp_agent')
            },
            img: 'https://cdn-icons-png.flaticon.com/128/14562/14562155.png'
        },
        {
            item: 'Our Telegram Community',
            action: () => {
                window.location.replace('tg://resolve?domain=oxyappofficial')
            },
            img: 'https://cdn-icons-png.flaticon.com/128/2111/2111646.png'
        }
    ]

    async function logout() {
        localStorage.removeItem('token')
        window.location.replace('/login')
    }


    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/index`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                let bal = data.balance
                setBalance(bal.recharge + bal.withdraw)
                setIncome(bal.income)
                setCommission(bal.team)

                setName(data.name);
                setPhone(data.isd + ' ' + data.phone)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <div className='pg me'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="me--top">
                <div className="left">
                    <div className="user--pic">
                        <img src={logo} width={'100%'} alt="" />
                    </div>
                    <div className="lf">
                        <div className="user--name">{name}</div>
                        <div className="user--ph">{phone}</div>
                    </div>
                </div>
            </div>

            <div className="me--bal">
                <div className="col">
                    <center>
                        <div className="col--tp">₹{balance}</div>
                        <div className="col--bp">My Asset</div>
                    </center>
                </div>
                <div className="col">
                    <center>
                        <div className="col--tp">₹{income}</div>
                        <div className="col--bp">My Income</div>
                    </center>
                </div>
                <div className="col">
                    <center>
                        <div className="col--tp">₹{commission}</div>
                        <div className="col--bp">Team Income</div>
                    </center>
                </div>
            </div>

            <div className="me--btn">
                <button onClick={() => window.location.href = '/#/recharge'}>Recharge</button>
                <button onClick={() => window.location.href = '/#/withdraw'}>Withdraw</button>
            </div>

            <div className="section">
                <div className="menu">
                    {options.map((item, index) => {
                        return (
                            <div className="col" key={index} onClick={item.action}>
                                <div className="col--lf">
                                    <div className="menu--icon">
                                        <img src={item.img} height={'100%'} alt="" />
                                    </div>
                                    <div className="menu--name">{item.item}</div>
                                </div>
                                <div className="col--rf"></div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <center>
                <button className="sign--out" onClick={logout}>Sign Out</button>
            </center>

            <BottomNav page="Me" />
        </div>
    )
}

export default Me