import React, { useState, useEffect } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import axios from 'axios'

import Error from '../Components/Error'
import Loading from '../Components/Loading'

import img from '../Images/no-data.png'
import { useNavigate } from 'react-router-dom';

const Team = ({ token }) => {
    let path = window.location.hash
    const getParams = path.split('?')[1]
    const query = new URLSearchParams(getParams);

    const navigate = useNavigate()

    const level = parseFloat(query.get('level'))
    let lvl = level > 3 || level < 1 ? 1 : level

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [records, setRecords] = useState([])

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/team?level=${lvl}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data;

                setRecords(data.records)

                setLoader(false)   
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='pg records'>

            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}
        
            <div className="header">
                <div className="icon" onClick={() => navigate(-1)}>
                    <BsArrowLeft />
                </div>
                <div className="pg--name">Level {lvl} Team</div>
            </div>

            <div className="section2">
                {records.length === 0 && (
                    <div className="no--data">
                        <center>
                            <div className="img">
                                <img src={img} alt="" />
                            </div>
                            <div className="txt">No Referral Found</div>
                        </center>
                    </div>
                )}

                {records.map((item, index) => {
                    let pd = new Date(item.date);
                    let ph = pd.getHours() > 12 ? pd.getHours() - 12 : pd.getHours();
                    let pm = pd.getHours() > 12 ? "PM" : "AM"
                    let pdi = ("0" + pd.getDate()).slice(-2) + "/" + ("0" + (pd.getMonth() + 1)).slice(-2) + "/" + ("0" + pd.getFullYear()).slice(-2) + " " + ("0" + ph).slice(-2) + ":" + ("0" + pd.getMinutes()).slice(-2) + "" + pm

                    return (
                        <div className="box" key={index}>
                            <div className="box--bottom" style={{ marginTop: 0}}>
                                <div className="col">
                                    <div className="col--lf">User:</div>
                                    <div className="col--rf">{item.phone}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Invested:</div>
                                    <div className="col--rf">₹{item.investments}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Commission:</div>
                                    <div className="col--rf">₹{item.commission}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Joined Date:</div>
                                    <div className="col--rf">{pdi}</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Team