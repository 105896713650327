import React, { useState, useEffect } from 'react'
import '../CSS/Recharge.css'
import axios from 'axios'

import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

import Error from '../Components/Error'
import Loading from '../Components/Loading'

const Recharge = ({ token }) => {
    const amo = [400, 3500, 8000, 22500, 40000]
    const navigate = useNavigate()

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [balance, setBalance] = useState(0)
    const [amount, setAmount] = useState('')

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/index`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setBalance(data.balance.recharge + data.balance.withdraw)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    async function onRecharge() {
        let minRec = 400

        if (amount < minRec) {
            setIsError(true)
            setErrorText(`Minimum amount to recharge is Rs400`)

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }

        setLoader(true)
        axios.post(`${process.env.REACT_APP_SERVER}/recharge/create/order`, { amount }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data
                window.location.href = data.url;

                const timerId = setTimeout(() => {
                    setLoader(false)
                }, 3000);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    return (
        <div className='pg recharge'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="recharge--top">
                <div className="header">
                    <div className="icon" onClick={() => navigate(-1)}>
                        <BsArrowLeft />
                    </div>
                    <div className="pg--name">Recharge</div>
                </div>

                <div className="rec--bottom">
                    <div className="txt">Available balance</div>
                    <div className="amount">₹{balance}</div>
                </div>
            </div>

            <div className="section">
                <div className="top">
                    <div className="title">Deposit Amount</div>
                    <div className="input">
                        <div className="bx--icon">₹</div>
                        <input type="number" placeholder='Enter amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="top">
                    <div className="title">Quick Amount</div>
                    <div className="amo--cols">
                        {amo.map((item, index) => {
                            return (
                                <div className="col" key={index} onClick={() => setAmount(item)}>₹{item}</div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <center>
                <button className="recharge--btn" onClick={onRecharge}>Recharge</button>
                <div className="si--txt" onClick={() => window.location.href = '/#/recharge/records'}>Recharge Records</div>
            </center>
        </div>
    )
}

export default Recharge