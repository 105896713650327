import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Login.css'

import { FaUserGroup } from 'react-icons/fa6'
import { IoKey } from 'react-icons/io5'
import { FaUser } from "react-icons/fa6";
import axios from 'axios';

import Error from '../Components/Error'
import Loading from '../Components/Loading'
import ReCAPTCHA from 'react-google-recaptcha';

const DELAY = 1500;

const Register = () => {
    let path = window.location.hash
    const getParams = path.split('?')[1]
    const query = new URLSearchParams(getParams);

    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('')
    const [inviter, setInviter] = useState(query.get('invite_code') ? query.get('invite_code') : '')

    const [loader, setLoader] = useState(false)
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')

    const [callback, setCallback] = useState("not fired");
    const [captchaToken, setCaptchaToken] = useState("[empty]");
    const [load, setLoad] = useState(false);
    const [expired, setExpired] = useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    const reCaptchaRef = useRef();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoad(true);
        }, DELAY);
        return () => clearTimeout(timer);
    }, []);

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
        if (captchaToken === null) setExpired(true);
    };

    const asyncScriptOnLoad = () => {
        setCallback("called!");
        setRecaptchaLoaded(true);
    };

    async function registerAcc() {
        if (!name) {
            setIsError(true)
            setErrorText('Please enter your phone number')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (!phone) {
            setIsError(true)
            setErrorText('Please enter your phone number')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (phone.length !== 10 || isNaN(phone)) {
            setIsError(true)
            setErrorText('Please enter valid phone number')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (!password) {
            setIsError(true)
            setErrorText('Please enter a password')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (password.length < 6) {
            setIsError(true)
            setErrorText('Password is less than 6 bits')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }

        setLoader(true)
        //;



        reCaptchaRef.current.executeAsync().then((value) => {
            axios.post(`${process.env.REACT_APP_SERVER}/register`, {
                isd: '+91', phone, password, name, inviter, captchaToken: value
        })
            .then(response => {
                window.location.href = '/#/login'
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
        })
    }

    return (
        <div className='pg login'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="tp--section">
                <div className="login--img">
                    <div className="img">
                        <img src="https://seeklogo.com/images/O/occidental-petroleum-logo-9C701B1F20-seeklogo.com.png" height={'30'} alt="" />
                    </div>
                    <div className="side--txt">OXYAPP</div>
                </div>

                <div className="login--wl">Register to win rewards,</div>
                <div className="login--bl">Join OXY and get free ₹71 as registration bonus</div>
            </div>

            <div className="login--form">
                <div className="form--title">Sign Up</div> <br />

                <div className="col">
                    <div className="col--title">Name</div>
                    <div className="form--input">
                        <div className="in--left">
                            <FaUser />
                        </div>
                        <input type="text" placeholder='Enter your name' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>

                <div className="col">
                    <div className="col--title">Phone Number</div>
                    <div className="form--input">
                        <div className="in--left">+91</div>
                        <input type="tel" maxLength={'10'} placeholder='Enter your phone number' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                </div>

                <div className="col">
                    <div className="col--title">Password</div>
                    <div className="form--input">
                        <div className="in--left">
                            <IoKey />
                        </div>
                        <input type="password" placeholder='Enter your password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>

                <div className="col">
                    <div className="col--title">Invitation code</div>
                    <div className="form--input">
                        <div className="in--left">
                            <FaUserGroup />
                        </div>
                        <input type="text" placeholder='Enter invitation code' value={inviter} onChange={(e) => setInviter(e.target.value)} />
                    </div>
                </div>

                {load && (<ReCAPTCHA ref={reCaptchaRef} asyncScriptOnLoad={asyncScriptOnLoad} sitekey="6LfZM6cpAAAAAMZn-l4aBRG4u_Y5SYuFyKy3PwMb" onChange={handleCaptchaChange} size="invisible" />)}

                <center>
                    <button className="login--btn" onClick={registerAcc}>Register</button>
                    <div className="login--bp">
                        <div className="bpi">Already have an account?</div>
                        <div className="bpii" onClick={() => window.location.href = '/#/login'}>Login now</div>
                    </div>
                </center>
            </div>
        </div>
    )
}

export default Register