import React, { useState, useEffect } from 'react'
import '../CSS/Recharge.css'
import axios from 'axios'

import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

import Error from '../Components/Error'
import Loading from '../Components/Loading'

const Withdraw = ({ token }) => {
    const navigate = useNavigate()

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [balance, setBalance] = useState(0)
    const [amount, setAmount] = useState('')

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/index`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setBalance(data.balance.withdraw)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    async function onWithdraw() {
        let minRec = 200

        if (amount < minRec) {
            setIsError(true)
            setErrorText(`Minimum withdrawal amount is Rs200`)

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }

        setLoader(true)
        axios.post(`${process.env.REACT_APP_SERVER}/withdraw/request`, { amount }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data
                setBalance(data.balance)

                setLoader(false)
                
                setIsError(true)
                setErrorText(`Withdrawal Successful`)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    if (error.response.data.redirect === true) {
                        window.location.href = '/#/account'
                    }


                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    return (
        <div className='pg recharge'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="recharge--top">
                <div className="header">
                    <div className="icon" onClick={() => navigate(-1)}>
                        <BsArrowLeft />
                    </div>
                    <div className="pg--name">Withdraw</div>
                </div>

                <div className="rec--bottom">
                    <div className="txt">Withdrawal balance</div>
                    <div className="amount">₹{balance}</div>
                </div>
            </div>

            <div className="section">
                <div className="top">
                    <div className="title">Withdraw Amount</div>
                    <div className="input">
                        <div className="bx--icon">₹</div>
                        <input type="number" placeholder='Enter amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                </div>
            </div>

            <center>
                <button className="recharge--btn" onClick={onWithdraw}>Withdraw</button>
                <div className="si--txt" onClick={() => window.location.href = '/#/withdraw/records'}>Withdraw Records</div>
            </center>

            <div className="nt--section">
                <div className="txt">1. Minimum withdrawal is ₹200</div>
                <div className="txt">2. Withdrawal time: 9:00AM - 5:00PM</div>
                <div className="txt">3. Withdrawal Fee: 12%</div> <br />
                <div className="txt">All withdrawals will be completed within 24 hours. If there are special exception, the order can take upto 72 hours. <br /> <br />If you have any question, please contact your dedicated customer service from our app.</div>
            </div>
        </div>
    )
}

export default Withdraw