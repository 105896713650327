import React from 'react'
import '../CSS/FAQ.css'

import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

const FAQ = () => {
    const navigate = useNavigate();

    return (
        <div className='pg faq'>
            <div className="header">
                <div className="icon" onClick={() => navigate(-1)}>
                    <BsArrowLeft />
                </div>
                <div className="pg--name">How to make money</div>
            </div>

            <div className="section">
                <div className="description">Welcome to OXY App, where smart investments pave the way to financial success! Discover how you can earn daily returns through investment plans and leverage referral programs to boost your income effortlessly.</div>

                <div className="steps">
                    <div className="mt">Steps To Make Money:</div>
                    <div className="col">
                        <div className="title">Step 1: Register and Recharge</div>
                        <div className="cs">i. Sign up on Oxy App to access a range of investment plans tailored to your financial goals.</div>
                        <div className="cs">ii. Recharge your account with INR to start investing in your chosen plans.</div>
                    </div>

                    <div className="col">
                        <div className="title">Step 2: Explore Investment Plans</div>
                        <div className="cs">i. Browse through our investment plans to find the one that suits your risk appetite and investment horizon.</div>
                        <div className="cs">ii. Enjoy daily returns on your investments until the plan maturity date, maximizing your earnings along the way.</div>
                    </div>

                    <div className="col">
                        <div className="title">Step 3: Free Plan for New Users</div>
                        <div className="cs">i. As a new user, take advantage of our free plan to experience the benefits of investing with Oxy App at no cost.</div>
                        <div className="cs">ii. Learn how our platform works and start earning returns without any initial investment.</div>
                    </div>

                    <div className="col">
                        <div className="title">Step 4: Unlock Referral Rewards</div>
                        <div className="cs">i. Refer friends and family to Oxy App using your unique referral link and earn commissions on their investments.</div>
                        <div className="cs">ii. Benefit from a multi-level referral system: earn 20% commission on 1st level referrals, 4% on 2nd level, and 2% on 3rd level referrals.</div>
                        <div className="cs">iii. Earn additional rewards when your 1st level referrals recharge their accounts for the first time, reaching the required number of verified referrals to claim rewards.</div>
                    </div>

                    <div className="col">
                        <div className="title">Step 5: Level Up Your Earnings</div>
                        <div className="cs">i. Grow your referral network to unlock higher earning potentials across multiple levels.</div>
                        <div className="cs">ii. Track your referral earnings and watch your passive income grow as your network expands.</div>
                    </div>

                    <div className="col">
                        <div className="title">Step 6: Reinvest and Compound Your Wealth</div>
                        <div className="cs">i. Reinvest your earnings or add more funds to your account to compound your wealth over time.</div>
                        <div className="cs">ii. Explore different investment strategies and optimize your portfolio for long-term financial growth.</div>
                    </div>
                </div> <br /><br />

                <div className="description">With Oxy App, making money through smart investments and strategic referrals has never been easier. Start your journey towards financial prosperity today!"</div>
            </div>
        </div>
    )
}

export default FAQ