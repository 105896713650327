import React, { useState } from 'react'
import '../CSS/Login.css'

import { IoKey } from 'react-icons/io5'
import { IoMdArrowDropdown } from 'react-icons/io'
import axios from 'axios';

import Error from '../Components/Error'
import Loading from '../Components/Loading'

const Login = () => {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    const [loader, setLoader] = useState(false)
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')

    async function loginAcc() {
        if (!phone) {
            setIsError(true)
            setErrorText('Please enter your phone number')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (phone.length !== 10 || isNaN(phone)) {
            setIsError(true)
            setErrorText('Please enter valid phone number')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (!password) {
            setIsError(true)
            setErrorText('Please enter a password')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (password.length < 6) {
            setIsError(true)
            setErrorText('Password is less than 6 bits')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }

        setLoader(true)
        axios.post(`${process.env.REACT_APP_SERVER}/login`, {
            isd: '+91', phone, password
        })
            .then(response => {
                let data = response.data;
                const token = data.token

                localStorage.setItem('token', token)
                //localStorage.setItem('country', data.country)
                //localStorage.setItem('symbol', data.currency === 'INR' ? '₹' : data.currency === 'EUR' ? '€' : '$')
                //localStorage.setItem('rate', data.currency === 'INR' ? 1 : data.currency === 'EUR' ? 87 : 83)

                window.location.replace('/#/')
                window.location.reload()
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }


    return (
        <div className='pg login'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="tp--section">
                <div className="login--img">
                    <div className="img">
                        <img src="https://seeklogo.com/images/O/occidental-petroleum-logo-9C701B1F20-seeklogo.com.png" height={'30'} alt="" />
                    </div>
                    <div className="side--txt">OXYAPP</div>
                </div>

                <div className="login--wl">Welcome back,</div>
                <div className="login--bl">Many rewards are waiting for you to collect</div>
            </div>

            <div className="login--form">
                <div className="form--title">Sign In</div> <br />

                <div className="col">
                    <div className="col--title">Phone Number</div>
                    <div className="form--input">
                        <div className="in--left">+91</div>
                        <input type="tel" maxLength={'10'} placeholder='Enter your phone number' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                </div>

                <div className="col">
                    <div className="col--title">Password</div>
                    <div className="form--input">
                        <div className="in--left">
                            <IoKey />
                        </div>
                        <input type="password" placeholder='Enter your password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>

                <center>
                    <button className="login--btn" onClick={loginAcc}>Login</button>
                    <div className="login--bp">
                        <div className="bpi">Don't have an account?</div>
                        <div className="bpii" onClick={() => window.location.href = '/#/register'}>Register now</div>
                    </div>
                </center>
            </div>
        </div>
    )
}

export default Login