import React from 'react'

const Error = ({ errorMsg }) => {
    return (
        <div className='error--container'>
            <div className="error--box">
                {errorMsg}
            </div>
        </div>
    )
}

export default Error