import React, { useEffect, useState} from 'react'
import '../CSS/Records.css'
import axios from 'axios'

import { BsArrowLeft } from "react-icons/bs";

import Error from '../Components/Error'
import Loading from '../Components/Loading'

import img from '../Images/no-data.png'
import { useNavigate } from 'react-router-dom';

const Records2 = ({ token }) => {
    const navigate = useNavigate()
    const [records, setRecords] = useState([])

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/withdraw/records`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data;

                setRecords(data.records)
                //setInvestments(data.investments)
                //setRevenue(data.revenue);

                setLoader(false)

            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const elements = [];
    for (let i = 0; i < 50; i++) {
        elements.push(<div className='col'></div>)
    }


    return (
        <div className='pg records'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="header">
                <div className="icon" onClick={() => navigate(-1)}>
                    <BsArrowLeft />
                </div>
                <div className="pg--name">Withdraw Records</div>
            </div>

            <div className="section2">
                {records.length === 0 && (
                    <div className="no--data">
                        <center>
                            <div className="img">
                                <img src={img} alt="" />
                            </div>
                            <div className="txt">No Withdraw Found</div>
                        </center>
                    </div>
                )}


                {records.map((item, index) => {
                    let pd = new Date(item.date);
                    let ph = pd.getHours() > 12 ? pd.getHours() - 12 : pd.getHours();
                    let pm = pd.getHours() > 12 ? "PM" : "AM"
                    let pdi = ("0" + pd.getDate()).slice(-2) + "/" + ("0" + (pd.getMonth() + 1)).slice(-2) + "/" + ("0" + pd.getFullYear()).slice(-2) + " " + ("0" + ph).slice(-2) + ":" + ("0" + pd.getMinutes()).slice(-2) + "" + pm

                    return (
                        <div className="box" key={index}>
                            <div className="box--top">
                                <center>
                                    <div className="bx--tp">₹{item.amount}</div>
                                    <div className="bx--bp">Amount Withdrawal</div>
                                </center>
                            </div>
                            <div className="divider">{elements}</div>
                            <div className="box--bottom">
                                <div className="col">
                                    <div className="col--lf">Order Status:</div>
                                    <div className={`col--rf status ${item.status}`}>{item.status}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Order Number:</div>
                                    <div className="col--rf">{item.order_id}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Actually:</div>
                                    <div className="col--rf">₹{item.amount * 0.88}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Service Fee:</div>
                                    <div className="col--rf">₹{item.amount * 0.12}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Request Time:</div>
                                    <div className="col--rf">{pdi}</div>
                                </div>
                                {item.status === 'Success' && <div className="col">
                                    <div className="col--lf">Arrived At:</div>
                                    <div className="col--rf">{item.arrived}</div>
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Records2