import React, { useState, useEffect } from 'react'
import '../CSS/Home.css'

import BottomNav from '../Components/BottomBar/index'
import axios from 'axios'
import Error from '../Components/Error'
import Loading from '../Components/Loading'

import logo from '../Images/logo.png'

const Home = ({ token, rate, symbol }) => {
  const [currentProductList, setCurrentProductList] = useState([]);
  const [balance, setBalance] = useState(0)
  const [name, setName] = useState('XXXXX XXX')
  const [phone, setPhone] = useState('+91 XXXXXXXXXX')
  const [off, setOff] = useState(true)

  const [loader, setLoader] = useState(false)
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('')

  async function getData() {
    setLoader(true);

    axios.get(`${process.env.REACT_APP_SERVER}/index`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        let data = response.data

        let plans = data.plans;
        setCurrentProductList(plans);

        setOff(data.verified)

        setBalance(data.balance.withdraw + data.balance.recharge)

        setName(data.name);
        setPhone(data.isd + ' ' + data.phone)

        setLoader(false)
      }).catch(error => {
        setLoader(false)
        setIsError(true)
        setErrorText(error.response.data.message)

        const timerId = setTimeout(() => {
          setIsError(false)
          setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
      })
  }

  useEffect(() => {
    getData()
  }, [])

  async function purchasePro(index) {
    setLoader(true);

    axios.post(`${process.env.REACT_APP_SERVER}/invest`, { index }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setLoader(false);

        setIsError(true)
        setErrorText('Invested Successfully')

        if (index > 0) {
          setOff(true)
          setBalance(balance - response.data.cut)
        }

        const timerId = setTimeout(() => {
          setIsError(false)
          setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
      }).catch(error => {
        setLoader(false)
        setIsError(true)
        setErrorText(error.response.data.message)

        const timerId = setTimeout(() => {
          setIsError(false)
          setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
      })
  }

  return (
    <div className='pg home'>
      {isError ? <Error errorMsg={errorText} /> : <></>}
      {loader ? <Loading /> : <></>}

      <div className="header">
        <div className="left">
          <div className="left--top">
            <div className="user--pic">
              <img src={logo} width={'100%'} alt="" />
            </div>
            <div className="left--right">
              <div className="user--name">{name}</div>
              <div className="user--ph">{phone}</div>
            </div>
          </div>
          <div className="left--bottom">
            <div className="txt">Available Balance</div>
            <div className="user--bal">₹{balance}</div>
          </div>
        </div>
        <div className="right">
          <button className="deposit--btn" onClick={() => window.location.href = '/#/recharge'}>Recharge</button> <br />
          <button className="withdraw--btn" onClick={() => window.location.href = '/#/withdraw'}>Withdraw</button>
        </div>
      </div>

      <div className="products">
        {currentProductList.map((item, index) => {
          return (
            <div className="box" key={index}>
              <div className="box--top">
                <div className="box--top--left">
                  <div className="pro--img">{index + 1}</div>
                  <div className="box--top--left--right">
                    <div className="pro--name">{item.item}</div>
                    {item.amount > 0 && !off ? <div className="pro--cost">
                      <span style={{ textDecoration: 'line-through' }}>₹{item.amount}</span> ₹{item.amount * 0.85}
                    </div> : <div className="pro--cost">
                      ₹{item.amount}
                    </div>}   
                  </div>
                </div>
              </div>
              <div className="pro--name" style={{ marginTop: 10, color: 'red' }}>{item.amount > 0 && !off && '* offer only for 1st purchase: 15% off'}</div>
              <div className="box--bottom">
                <div className="box--bottom--left">
                  <div className="col">
                    <div className="col--top">Product cycle</div>
                    <div className="col--bottom">{item.period} Days</div>
                  </div>
                  <div className="col">
                    <div className="col--top">Total Income</div>
                    <div className="col--bottom">₹{item.period * item.daily}</div>
                  </div>
                  <div className="col">
                    <div className="col--top">Daily income</div>
                  </div>
                  <div className="col">
                    <div className="amo">₹{item.daily}</div>
                  </div>
                </div>
                <button className="purchase--btn" onClick={() => purchasePro(index)}>Buy Now</button>
              </div>
            </div>
          )
        })}
      </div>

      <div className="fixed--menu">
        <div className="col" onClick={() => window.location.replace('tg://resolve?domain=oxyappofficial')}>
          <img src="https://cdn-icons-png.flaticon.com/128/2111/2111646.png" width={'100%'} alt="" />
        </div>
        <div className="col">
          <div className="col" onClick={() => window.location.replace('tg://resolve?domain=oxyapp_agent')}>
            <img src="https://cdn-icons-png.flaticon.com/128/10439/10439810.png" width={'100%'} alt="" />
          </div>
        </div>
      </div>

      <BottomNav page="Home" />
    </div>
  )
}

export default Home