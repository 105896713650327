import React, { useState, useEffect } from 'react'
import '../CSS/Account.css'
import axios from 'axios'

import { BsArrowLeft } from 'react-icons/bs'

import { useNavigate } from 'react-router-dom'
import Error from '../Components/Error'
import Loading from '../Components/Loading'

const Account = ({ token }) => {
    const navigate = useNavigate();

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [preset, setPreset] = useState({
        name: '**********',
        account_no: '**********',
        IFSC: '***********'
    })

    const [holder, setHolder] = useState('')
    const [accountNo, setAccountNo] = useState('')
    const [IFSC, setIFSC] = useState('')

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/index`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                let acc = data.account

                if  (data.account.isAccount) {
                    setPreset({
                        ...preset,
                        name: acc.holder,
                        account_no: acc.number,
                        IFSC: acc.IFSC
                    })

                    setAccountNo(acc.number)
                    setHolder(acc.holder)
                    setIFSC(acc.IFSC)
                }

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])


    async function onConfirm() {
        setLoader(true);

        axios.post(`${process.env.REACT_APP_SERVER}/add/account`, {
            holder, accountNo, IFSC
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                let acc = data

                setPreset({
                    ...preset,
                    name: acc.holder,
                    account_no: acc.accountNo,
                    IFSC: acc.IFSC
                })

                setLoader(false)

                setIsError(true)
                setErrorText('Bank account updated successfully')

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    return (
        <div className='pg account'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="acc--top">
                <div className="header">
                    <div className="icon" onClick={() => navigate(-1)}>
                        <BsArrowLeft />
                    </div>
                    <div className="pg--name">Bank Account</div>
                </div>

                <div className="acc--tpl">
                    <div className="col">
                        <div className="col--tp">Account No.</div>
                        <div className="col--bp">{preset.account_no}</div>
                    </div>
                    <div className="col">
                        <div className="col--tp">Cardholder's Name.</div>
                        <div className="col--bp">{preset.name}</div>
                    </div>
                </div>
            </div>


            <div className="section">
                <div className="top">
                    <div className="title">Cardholder name</div>
                    <div className="input">
                        <input type="text" placeholder='Enter cardholder name' value={holder} onChange={(e) => setHolder(e.target.value)} />
                    </div>
                </div> <br />

                <div className="top">
                    <div className="title">Bank Account</div>
                    <div className="input">
                        <input type="number" placeholder='Enter bank account' value={accountNo} onChange={(e) => setAccountNo(e.target.value)} />
                    </div>
                </div> <br />

                <div className="top">
                    <div className="title">IFSC Code</div>
                    <div className="input">
                        <input type="text" placeholder='Enter IFSC code' value={IFSC} onChange={(e) => setIFSC(e.target.value.toUpperCase())} />
                    </div>
                </div>

                <center>
                    <button className="recharge--btn" onClick={onConfirm}>Confirm</button>
                </center>
            </div>
        </div>
    )
}

export default Account