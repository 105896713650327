import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';

import Home from './Pages/Home';
import Invest from './Pages/Invest';
import Records3 from './Pages/Records3';
import Recharge from './Pages/Recharge';
import Records from './Pages/Records';
import Records2 from './Pages/Records2';
import Me from './Pages/Me';
import Withdraw from './Pages/Withdraw';
import Reward from './Pages/Reward';
import Invite from './Pages/Invite';
import Team from './Pages/Team';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Account from './Pages/Account';
import FAQ from './Pages/FAQ';

function App() {
  const forceHash = window.location.pathname !== '/' || (window.location.pathname === '/' && window.location.hash === '')

  if (forceHash) {
    if (window.location.pathname !== '/') {
      window.location.replace('/#' + window.location.pathname)
    } else {
      window.location.replace('/#')
    }
  }

  let token = localStorage.getItem('token')
  let rate = localStorage.getItem('rate')
  let symbol = localStorage.getItem('symbol')

  return (
    <div className="App">
      <HashRouter>
        {token ? (
          <Routes>
            <Route path='/' element={<Home token={token} symbol={symbol} rate={rate} />} />
            <Route path='/account' element={<Account token={token} symbol={symbol} rate={rate} />} />
            <Route path='/investments' element={<Invest token={token} symbol={symbol} rate={rate} />} />
            <Route path='/team/records' element={<Team token={token} symbol={symbol} rate={rate} />} />
            <Route path='/mine' element={<Me token={token} symbol={symbol} rate={rate} />} />
            <Route path='/recharge' element={<Recharge token={token} symbol={symbol} rate={rate} />} />
            <Route path='/withdraw' element={<Withdraw token={token} symbol={symbol} rate={rate} />} />
            <Route path='/reward' element={<Reward token={token} symbol={symbol} rate={rate} />} />
            <Route path='/my/team' element={<Invite token={token} symbol={symbol} rate={rate} />} />
            <Route path='/funds/records' element={<Records3 token={token} symbol={symbol} rate={rate} />} />
            <Route path='/recharge/records' element={<Records token={token} symbol={symbol} rate={rate} />} />
            <Route path='/withdraw/records' element={<Records2 token={token} symbol={symbol} rate={rate} />} />
            <Route path='/how-to-make-money' element={<FAQ token={token} symbol={symbol} rate={rate} />} />
          </Routes>
        ): (
          <Routes>
            <Route path='/' element={<Login />} />
          </Routes>
        )}

        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;