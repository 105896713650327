import React, { useState, useEffect } from 'react'
import '../CSS/Invest.css';

import axios from 'axios'

import BottomNav from '../Components/BottomBar/index'

import Error from '../Components/Error'
import Loading from '../Components/Loading'

import img from '../Images/no-data.png'

const Invest = ({ token }) => {
    const [records, setRecords] = useState([])

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/investments`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data;

                setRecords(data.records)
                //setInvestments(data.investments)
                //setRevenue(data.revenue);

                setLoader(false)

            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='invest pg'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="header">My Investments</div>

            <div className="section">
                {records.length === 0 && (
                    <div className="no--data">
                        <center>
                            <div className="img">
                                <img src={img} alt="" />
                            </div>
                            <div className="txt">No Investment Found</div>
                        </center>
                    </div>
                )}

                {records.map((item, index) => {
                    let pd = new Date(item.date);
                    let ph = pd.getHours() > 12 ? pd.getHours() - 12 : pd.getHours();
                    let pm = pd.getHours() > 12 ? "PM" : "AM"
                    let pdi = ("0" + pd.getDate()).slice(-2) + "/" + ("0" + (pd.getMonth() + 1)).slice(-2)

                    let ed = new Date(item.date + (item.period * 60 * 60 * 24 * 1000));
                    let eh = ed.getHours() > 12 ? ed.getHours() - 12 : ed.getHours();
                    let em = ed.getHours() > 12 ? "PM" : "AM"
                    let edi = ("0" + ed.getDate()).slice(-2) + "/" + ("0" + (ed.getMonth() + 1)).slice(-2) + " " + ("0" + eh).slice(-2) + ":" + ("0" + ed.getMinutes()).slice(-2) + "" + em

                    return (
                        <div className="box" key={index}>
                            <div className="left">
                                <center>
                                    <div className="lf--top">₹{item.total}</div>
                                    <div className="lf--bottom">My Income</div>
                                </center>
                            </div>

                            <div className="right">
                                <div className="col">
                                    <div className="col--lf">Plan:</div>
                                    <div className="col--rf">{item.name}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Inv Amount:</div>
                                    <div className="col--rf">₹{item.amount}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Received:</div>
                                    <div className="col--rf">{item.day} / {item.period}</div>
                                </div>
                                <div className="col">
                                    <div className="col--lf">Purchased On:</div>
                                    <div className="col--rf">{pdi}/2024</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <BottomNav page="Invest" />
        </div>
    )
}

export default Invest