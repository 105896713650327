import React, { useState, useEffect } from 'react'
import '../CSS/Invite.css'
import axios from 'axios'

import BottomNav from '../Components/BottomBar/index'

import Error from '../Components/Error'
import Loading from '../Components/Loading'

import img from '../Images/team/lv1.png'
import img2 from '../Images/team/lv2.png'
import img3 from '../Images/team/lv3.png'

const Invite = ({ token }) => {
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [referral, setReferral] = useState({
        lv1: 0,
        lv2: 0,
        lv3: 0
    })

    const [income, setIncome] = useState({
        lv1: 0,
        lv2: 0,
        lv3: 0
    })

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/index`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data;

                setLoader(false)

                let ref = data.referrals.people
                let inc = data.referrals.income

                setReferral({
                    lv1: ref.lv1,
                    lv2: ref.lv2,
                    lv3: ref.lv3
                })

                setIncome({
                    lv1: inc.lv1,
                    lv2: inc.lv2,
                    lv3: inc.lv3
                })
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='pg invite'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="header">My Team</div>

            <div className="ti--box--container">
                <div className="ti--box">
                    <div className="ti--box--top">
                        <div className="ti--box--tpl">
                            <div className="img">
                                <img src={img} width={'100%'} alt="" />
                            </div>
                            <div className="ti--lvl">Level 1</div>
                        </div>

                        <div className="li--view" onClick={() => window.location.href = '/#/team/records?level=1'}>view records</div>
                    </div>

                    <div className="ti--box--bottom">
                        <div className="col">
                            <center>
                                <div className="col--tp">{referral.lv1}</div>
                                <div className="col--bp">People</div>
                            </center>
                        </div>
                        <div className="col">
                            <center>
                                <div className="col--tp">₹{income.lv1}</div>
                                <div className="col--bp">Commission (20%)</div>
                            </center>
                        </div>
                    </div>
                </div>

                <div className="ti--box">
                    <div className="ti--box--top">
                        <div className="ti--box--tpl">
                            <div className="img">
                                <img src={img2} width={'100%'} alt="" />
                            </div>
                            <div className="ti--lvl">Level 2</div>
                        </div>

                        <div className="li--view" onClick={() => window.location.href = '/#/team/records?level=2'}>view records</div>
                    </div>

                    <div className="ti--box--bottom">
                        <div className="col">
                            <center>
                                <div className="col--tp">{referral.lv2}</div>
                                <div className="col--bp">People</div>
                            </center>
                        </div>
                        <div className="col">
                            <center>
                                <div className="col--tp">₹{income.lv2}</div>
                                <div className="col--bp">Commission (4%)</div>
                            </center>
                        </div>
                    </div>
                </div>


                <div className="ti--box">
                    <div className="ti--box--top">
                        <div className="ti--box--tpl">
                            <div className="img">
                                <img src={img3} width={'100%'} alt="" />
                            </div>
                            <div className="ti--lvl">Level 3</div>
                        </div>

                        <div className="li--view" onClick={() => window.location.href = '/#/team/records?level=3'}>view records</div>
                    </div>

                    <div className="ti--box--bottom">
                        <div className="col">
                            <center>
                                <div className="col--tp">{referral.lv3}</div>
                                <div className="col--bp">People</div>
                            </center>
                        </div>
                        <div className="col">
                            <center>
                                <div className="col--tp">₹{income.lv3}</div>
                                <div className="col--bp">Commission (2%)</div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>

            <BottomNav page="Team" />
        </div>
    )
}

export default Invite